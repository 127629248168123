import React from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";

import "./style.scss";

const Page404 = () => {
  return (
    <>
      <Helmet>
        <body className="page-404" />
      </Helmet>
      <Layout>
        <SEO title="404" />
        <section className="page-404__section">
          <div>
            <h1>404</h1>
            <h2>
              Aïe&nbsp;! <br /> <span className="page-404__introuvable">page introuvable</span>
            </h2>
            <button onClick={() => navigate(-1)} className="page-404__btn">
              Retour
            </button>
          </div>
          <div className="page-404__images">
            <StaticImage
              src="../images/404/prise.png"
              alt="prise"
              placeholder="blurred"
              width={500}
              imgStyle={{
                objectFit: 'contain'
              }}
              className="page-404__wrapper"
            />
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Page404;
